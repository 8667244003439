export function removeDuplicates(array) {
  const set = new Set();
  array.forEach(item => set.add(item));
  return Array.from(set);
}
export function removeItem(array, item) {
  const index = array.indexOf(item);
  if (index >= 0) {
    array.splice(index, 1);
  }
}

import { elapsed, createValueHistory, SESSION_TIME_OUT_DELAY, toServerDuration, addEventListeners, relativeNow } from '@datadog/browser-core';
// Arbitrary value to cap number of element for memory consumption in the browser
export const MAX_PAGE_STATE_ENTRIES = 4000;
// Arbitrary value to cap number of element for backend & to save bandwidth
export const MAX_PAGE_STATE_ENTRIES_SELECTABLE = 500;
export const PAGE_STATE_CONTEXT_TIME_OUT_DELAY = SESSION_TIME_OUT_DELAY;
export function startPageStateHistory(configuration, maxPageStateEntriesSelectable = MAX_PAGE_STATE_ENTRIES_SELECTABLE) {
  const pageStateEntryHistory = createValueHistory({
    expireDelay: PAGE_STATE_CONTEXT_TIME_OUT_DELAY,
    maxEntries: MAX_PAGE_STATE_ENTRIES
  });
  let currentPageState;
  addPageState(getPageState(), relativeNow());
  const {
    stop: stopEventListeners
  } = addEventListeners(configuration, window, ["pageshow" /* DOM_EVENT.PAGE_SHOW */, "focus" /* DOM_EVENT.FOCUS */, "blur" /* DOM_EVENT.BLUR */, "visibilitychange" /* DOM_EVENT.VISIBILITY_CHANGE */, "resume" /* DOM_EVENT.RESUME */, "freeze" /* DOM_EVENT.FREEZE */, "pagehide" /* DOM_EVENT.PAGE_HIDE */], event => {
    addPageState(computePageState(event), event.timeStamp);
  }, {
    capture: true
  });
  function addPageState(nextPageState, startTime = relativeNow()) {
    if (nextPageState === currentPageState) {
      return;
    }
    currentPageState = nextPageState;
    pageStateEntryHistory.closeActive(startTime);
    pageStateEntryHistory.add({
      state: currentPageState,
      startTime
    }, startTime);
  }
  const pageStateHistory = {
    findAll: (eventStartTime, duration) => {
      const pageStateEntries = pageStateEntryHistory.findAll(eventStartTime, duration);
      if (pageStateEntries.length === 0) {
        return;
      }
      const pageStateServerEntries = [];
      // limit the number of entries to return
      const limit = Math.max(0, pageStateEntries.length - maxPageStateEntriesSelectable);
      // loop page state entries backward to return the selected ones in desc order
      for (let index = pageStateEntries.length - 1; index >= limit; index--) {
        const pageState = pageStateEntries[index];
        // compute the start time relative to the event start time (ex: to be relative to the view start time)
        const relativeStartTime = elapsed(eventStartTime, pageState.startTime);
        pageStateServerEntries.push({
          state: pageState.state,
          start: toServerDuration(relativeStartTime)
        });
      }
      return pageStateServerEntries;
    },
    wasInPageStateAt: (state, startTime) => pageStateHistory.wasInPageStateDuringPeriod(state, startTime, 0),
    wasInPageStateDuringPeriod: (state, startTime, duration) => pageStateEntryHistory.findAll(startTime, duration).some(pageState => pageState.state === state),
    addPageState,
    stop: () => {
      stopEventListeners();
      pageStateEntryHistory.stop();
    }
  };
  return pageStateHistory;
}
function computePageState(event) {
  if (event.type === "freeze" /* DOM_EVENT.FREEZE */) {
    return "frozen" /* PageState.FROZEN */;
  } else if (event.type === "pagehide" /* DOM_EVENT.PAGE_HIDE */) {
    return event.persisted ? "frozen" /* PageState.FROZEN */ : "terminated" /* PageState.TERMINATED */;
  }
  return getPageState();
}
function getPageState() {
  if (document.visibilityState === 'hidden') {
    return "hidden" /* PageState.HIDDEN */;
  }
  if (document.hasFocus()) {
    return "active" /* PageState.ACTIVE */;
  }
  return "passive" /* PageState.PASSIVE */;
}

import { noop } from '../tools/utils/functionUtils';
import { addEventListener } from './addEventListener';
export function runOnReadyState(configuration, expectedReadyState, callback) {
  if (document.readyState === expectedReadyState || document.readyState === 'complete') {
    callback();
    return {
      stop: noop
    };
  }
  const eventName = expectedReadyState === 'complete' ? "load" /* DOM_EVENT.LOAD */ : "DOMContentLoaded" /* DOM_EVENT.DOM_CONTENT_LOADED */;
  return addEventListener(configuration, window, eventName, callback, {
    once: true
  });
}
export function asyncRunOnReadyState(configuration, expectedReadyState) {
  return new Promise(resolve => {
    runOnReadyState(configuration, expectedReadyState, resolve);
  });
}

import { SESSION_TIME_OUT_DELAY, relativeNow, createValueHistory } from '@datadog/browser-core';
/**
 * We want to attach to an event:
 * - the url corresponding to its start
 * - the referrer corresponding to the previous view url (or document referrer for initial view)
 */
export const URL_CONTEXT_TIME_OUT_DELAY = SESSION_TIME_OUT_DELAY;
export function startUrlContexts(lifeCycle, locationChangeObservable, location) {
  const urlContextHistory = createValueHistory({
    expireDelay: URL_CONTEXT_TIME_OUT_DELAY
  });
  let previousViewUrl;
  lifeCycle.subscribe(1 /* LifeCycleEventType.BEFORE_VIEW_CREATED */, ({
    startClocks
  }) => {
    const viewUrl = location.href;
    urlContextHistory.add(buildUrlContext({
      url: viewUrl,
      referrer: !previousViewUrl ? document.referrer : previousViewUrl
    }), startClocks.relative);
    previousViewUrl = viewUrl;
  });
  lifeCycle.subscribe(5 /* LifeCycleEventType.AFTER_VIEW_ENDED */, ({
    endClocks
  }) => {
    urlContextHistory.closeActive(endClocks.relative);
  });
  const locationChangeSubscription = locationChangeObservable.subscribe(({
    newLocation
  }) => {
    const current = urlContextHistory.find();
    if (current) {
      const changeTime = relativeNow();
      urlContextHistory.closeActive(changeTime);
      urlContextHistory.add(buildUrlContext({
        url: newLocation.href,
        referrer: current.referrer
      }), changeTime);
    }
  });
  function buildUrlContext({
    url,
    referrer
  }) {
    return {
      url,
      referrer
    };
  }
  return {
    findUrl: startTime => urlContextHistory.find(startTime),
    stop: () => {
      locationChangeSubscription.unsubscribe();
      urlContextHistory.stop();
    }
  };
}

// eslint-disable-next-line no-restricted-syntax
export class Observable {
  constructor(onFirstSubscribe) {
    this.onFirstSubscribe = onFirstSubscribe;
    this.observers = [];
  }
  subscribe(f) {
    this.observers.push(f);
    if (this.observers.length === 1 && this.onFirstSubscribe) {
      this.onLastUnsubscribe = this.onFirstSubscribe(this) || undefined;
    }
    return {
      unsubscribe: () => {
        this.observers = this.observers.filter(other => f !== other);
        if (!this.observers.length && this.onLastUnsubscribe) {
          this.onLastUnsubscribe();
        }
      }
    };
  }
  notify(data) {
    this.observers.forEach(observer => observer(data));
  }
}
export function mergeObservables(...observables) {
  return new Observable(globalObservable => {
    const subscriptions = observables.map(observable => observable.subscribe(data => globalObservable.notify(data)));
    return () => subscriptions.forEach(subscription => subscription.unsubscribe());
  });
}

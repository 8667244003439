import { instrumentMethod } from '../tools/instrumentMethod';
import { monitor } from '../tools/monitor';
import { Observable } from '../tools/observable';
import { clocksNow } from '../tools/utils/timeUtils';
import { normalizeUrl } from '../tools/utils/urlPolyfill';
let fetchObservable;
export function initFetchObservable() {
  if (!fetchObservable) {
    fetchObservable = createFetchObservable();
  }
  return fetchObservable;
}
export function resetFetchObservable() {
  fetchObservable = undefined;
}
function createFetchObservable() {
  return new Observable(observable => {
    if (!window.fetch) {
      return;
    }
    const {
      stop
    } = instrumentMethod(window, 'fetch', call => beforeSend(call, observable), {
      computeHandlingStack: true
    });
    return stop;
  });
}
function beforeSend({
  parameters,
  onPostCall,
  handlingStack
}, observable) {
  const [input, init] = parameters;
  let methodFromParams = init && init.method;
  if (methodFromParams === undefined && input instanceof Request) {
    methodFromParams = input.method;
  }
  const method = methodFromParams !== undefined ? String(methodFromParams).toUpperCase() : 'GET';
  const url = input instanceof Request ? input.url : normalizeUrl(String(input));
  const startClocks = clocksNow();
  const context = {
    state: 'start',
    init,
    input,
    method,
    startClocks,
    url,
    handlingStack
  };
  observable.notify(context);
  // Those properties can be changed by observable subscribers
  parameters[0] = context.input;
  parameters[1] = context.init;
  onPostCall(responsePromise => afterSend(observable, responsePromise, context));
}
function afterSend(observable, responsePromise, startContext) {
  const context = startContext;
  function reportFetch(partialContext) {
    context.state = 'resolve';
    Object.assign(context, partialContext);
    observable.notify(context);
  }
  responsePromise.then(monitor(response => {
    reportFetch({
      response,
      responseType: response.type,
      status: response.status,
      isAborted: false
    });
  }), monitor(error => {
    var _a, _b;
    reportFetch({
      status: 0,
      isAborted: ((_b = (_a = context.init) === null || _a === void 0 ? void 0 : _a.signal) === null || _b === void 0 ? void 0 : _b.aborted) || error instanceof DOMException && error.code === DOMException.ABORT_ERR,
      error
    });
  }));
}

import { setTimeout } from '../tools/timer';
import { clocksNow, ONE_MINUTE, ONE_SECOND } from '../tools/utils/timeUtils';
import { ONE_MEBI_BYTE, ONE_KIBI_BYTE } from '../tools/utils/byteUtils';
import { isServerError } from '../tools/utils/responseUtils';
import { ErrorSource } from '../domain/error/error.types';
export const MAX_ONGOING_BYTES_COUNT = 80 * ONE_KIBI_BYTE;
export const MAX_ONGOING_REQUESTS = 32;
export const MAX_QUEUE_BYTES_COUNT = 3 * ONE_MEBI_BYTE;
export const MAX_BACKOFF_TIME = ONE_MINUTE;
export const INITIAL_BACKOFF_TIME = ONE_SECOND;
export function sendWithRetryStrategy(payload, state, sendStrategy, trackType, reportError) {
  if (state.transportStatus === 0 /* TransportStatus.UP */ && state.queuedPayloads.size() === 0 && state.bandwidthMonitor.canHandle(payload)) {
    send(payload, state, sendStrategy, {
      onSuccess: () => retryQueuedPayloads(0 /* RetryReason.AFTER_SUCCESS */, state, sendStrategy, trackType, reportError),
      onFailure: () => {
        state.queuedPayloads.enqueue(payload);
        scheduleRetry(state, sendStrategy, trackType, reportError);
      }
    });
  } else {
    state.queuedPayloads.enqueue(payload);
  }
}
function scheduleRetry(state, sendStrategy, trackType, reportError) {
  if (state.transportStatus !== 2 /* TransportStatus.DOWN */) {
    return;
  }
  setTimeout(() => {
    const payload = state.queuedPayloads.first();
    send(payload, state, sendStrategy, {
      onSuccess: () => {
        state.queuedPayloads.dequeue();
        state.currentBackoffTime = INITIAL_BACKOFF_TIME;
        retryQueuedPayloads(1 /* RetryReason.AFTER_RESUME */, state, sendStrategy, trackType, reportError);
      },
      onFailure: () => {
        state.currentBackoffTime = Math.min(MAX_BACKOFF_TIME, state.currentBackoffTime * 2);
        scheduleRetry(state, sendStrategy, trackType, reportError);
      }
    });
  }, state.currentBackoffTime);
}
function send(payload, state, sendStrategy, {
  onSuccess,
  onFailure
}) {
  state.bandwidthMonitor.add(payload);
  sendStrategy(payload, response => {
    state.bandwidthMonitor.remove(payload);
    if (!shouldRetryRequest(response)) {
      state.transportStatus = 0 /* TransportStatus.UP */;
      onSuccess();
    } else {
      // do not consider transport down if another ongoing request could succeed
      state.transportStatus = state.bandwidthMonitor.ongoingRequestCount > 0 ? 1 /* TransportStatus.FAILURE_DETECTED */ : 2 /* TransportStatus.DOWN */;
      payload.retry = {
        count: payload.retry ? payload.retry.count + 1 : 1,
        lastFailureStatus: response.status
      };
      onFailure();
    }
  });
}
function retryQueuedPayloads(reason, state, sendStrategy, trackType, reportError) {
  if (reason === 0 /* RetryReason.AFTER_SUCCESS */ && state.queuedPayloads.isFull() && !state.queueFullReported) {
    reportError({
      message: `Reached max ${trackType} events size queued for upload: ${MAX_QUEUE_BYTES_COUNT / ONE_MEBI_BYTE}MiB`,
      source: ErrorSource.AGENT,
      startClocks: clocksNow()
    });
    state.queueFullReported = true;
  }
  const previousQueue = state.queuedPayloads;
  state.queuedPayloads = newPayloadQueue();
  while (previousQueue.size() > 0) {
    sendWithRetryStrategy(previousQueue.dequeue(), state, sendStrategy, trackType, reportError);
  }
}
function shouldRetryRequest(response) {
  return response.type !== 'opaque' && (response.status === 0 && !navigator.onLine || response.status === 408 || response.status === 429 || isServerError(response.status));
}
export function newRetryState() {
  return {
    transportStatus: 0 /* TransportStatus.UP */,
    currentBackoffTime: INITIAL_BACKOFF_TIME,
    bandwidthMonitor: newBandwidthMonitor(),
    queuedPayloads: newPayloadQueue(),
    queueFullReported: false
  };
}
function newPayloadQueue() {
  const queue = [];
  return {
    bytesCount: 0,
    enqueue(payload) {
      if (this.isFull()) {
        return;
      }
      queue.push(payload);
      this.bytesCount += payload.bytesCount;
    },
    first() {
      return queue[0];
    },
    dequeue() {
      const payload = queue.shift();
      if (payload) {
        this.bytesCount -= payload.bytesCount;
      }
      return payload;
    },
    size() {
      return queue.length;
    },
    isFull() {
      return this.bytesCount >= MAX_QUEUE_BYTES_COUNT;
    }
  };
}
function newBandwidthMonitor() {
  return {
    ongoingRequestCount: 0,
    ongoingByteCount: 0,
    canHandle(payload) {
      return this.ongoingRequestCount === 0 || this.ongoingByteCount + payload.bytesCount <= MAX_ONGOING_BYTES_COUNT && this.ongoingRequestCount < MAX_ONGOING_REQUESTS;
    },
    add(payload) {
      this.ongoingRequestCount += 1;
      this.ongoingByteCount += payload.bytesCount;
    },
    remove(payload) {
      this.ongoingRequestCount -= 1;
      this.ongoingByteCount -= payload.bytesCount;
    }
  };
}

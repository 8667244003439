import { setTimeout, relativeNow, runOnReadyState, clearTimeout } from '@datadog/browser-core';
import { getNavigationEntry } from '../../../browser/performanceUtils';
export function trackNavigationTimings(configuration, callback, getNavigationEntryImpl = getNavigationEntry) {
  return waitAfterLoadEvent(configuration, () => {
    const entry = getNavigationEntryImpl();
    if (!isIncompleteNavigation(entry)) {
      callback(processNavigationEntry(entry));
    }
  });
}
function processNavigationEntry(entry) {
  return {
    domComplete: entry.domComplete,
    domContentLoaded: entry.domContentLoadedEventEnd,
    domInteractive: entry.domInteractive,
    loadEvent: entry.loadEventEnd,
    // In some cases the value reported is negative or is larger
    // than the current page time. Ignore these cases:
    // https://github.com/GoogleChrome/web-vitals/issues/137
    // https://github.com/GoogleChrome/web-vitals/issues/162
    firstByte: entry.responseStart >= 0 && entry.responseStart <= relativeNow() ? entry.responseStart : undefined
  };
}
function isIncompleteNavigation(entry) {
  return entry.loadEventEnd <= 0;
}
function waitAfterLoadEvent(configuration, callback) {
  let timeoutId;
  const {
    stop: stopOnReadyState
  } = runOnReadyState(configuration, 'complete', () => {
    // Invoke the callback a bit after the actual load event, so the "loadEventEnd" timing is accurate
    timeoutId = setTimeout(() => callback());
  });
  return {
    stop: () => {
      stopOnReadyState();
      clearTimeout(timeoutId);
    }
  };
}

import { addEventListeners } from '@datadog/browser-core';
export function trackFirstHidden(configuration, eventTarget = window) {
  let timeStamp;
  let stopListeners;
  if (document.visibilityState === 'hidden') {
    timeStamp = 0;
  } else {
    timeStamp = Infinity;
    ({
      stop: stopListeners
    } = addEventListeners(configuration, eventTarget, ["pagehide" /* DOM_EVENT.PAGE_HIDE */, "visibilitychange" /* DOM_EVENT.VISIBILITY_CHANGE */], event => {
      if (event.type === "pagehide" /* DOM_EVENT.PAGE_HIDE */ || document.visibilityState === 'hidden') {
        timeStamp = event.timeStamp;
        stopListeners();
      }
    }, {
      capture: true
    }));
  }
  return {
    get timeStamp() {
      return timeStamp;
    },
    stop() {
      stopListeners === null || stopListeners === void 0 ? void 0 : stopListeners();
    }
  };
}

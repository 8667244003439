import { trackCumulativeLayoutShift } from './trackCumulativeLayoutShift';
import { trackInteractionToNextPaint } from './trackInteractionToNextPaint';
import { trackLoadingTime } from './trackLoadingTime';
import { trackScrollMetrics } from './trackScrollMetrics';
export function trackCommonViewMetrics(lifeCycle, domMutationObservable, windowOpenObservable, configuration, scheduleViewUpdate, loadingType, viewStart) {
  const commonViewMetrics = {};
  const {
    stop: stopLoadingTimeTracking,
    setLoadEvent
  } = trackLoadingTime(lifeCycle, domMutationObservable, windowOpenObservable, configuration, loadingType, viewStart, newLoadingTime => {
    commonViewMetrics.loadingTime = newLoadingTime;
    scheduleViewUpdate();
  });
  const {
    stop: stopScrollMetricsTracking
  } = trackScrollMetrics(configuration, viewStart, newScrollMetrics => {
    commonViewMetrics.scroll = newScrollMetrics;
  });
  const {
    stop: stopCLSTracking
  } = trackCumulativeLayoutShift(configuration, viewStart.relative, cumulativeLayoutShift => {
    commonViewMetrics.cumulativeLayoutShift = cumulativeLayoutShift;
    scheduleViewUpdate();
  });
  const {
    stop: stopINPTracking,
    getInteractionToNextPaint,
    setViewEnd
  } = trackInteractionToNextPaint(configuration, viewStart.relative, loadingType);
  return {
    stop: () => {
      stopLoadingTimeTracking();
      stopCLSTracking();
      stopScrollMetricsTracking();
    },
    stopINPTracking,
    setLoadEvent,
    setViewEnd,
    getCommonViewMetrics: () => {
      commonViewMetrics.interactionToNextPaint = getInteractionToNextPaint();
      return commonViewMetrics;
    }
  };
}

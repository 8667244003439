import { display, addEventListener } from '@datadog/browser-core';
export const REMOTE_CONFIGURATION_URL = 'https://d3uc069fcn7uxw.cloudfront.net/configuration';
export function fetchAndApplyRemoteConfiguration(initConfiguration, callback) {
  fetchRemoteConfiguration(initConfiguration, remoteInitConfiguration => {
    callback(applyRemoteConfiguration(initConfiguration, remoteInitConfiguration));
  });
}
export function applyRemoteConfiguration(initConfiguration, remoteInitConfiguration) {
  return {
    ...initConfiguration,
    ...remoteInitConfiguration
  };
}
export function fetchRemoteConfiguration(configuration, callback) {
  const xhr = new XMLHttpRequest();
  addEventListener(configuration, xhr, 'load', function () {
    if (xhr.status === 200) {
      callback(JSON.parse(xhr.responseText));
    } else {
      displayRemoteConfigurationFetchingError();
    }
  });
  addEventListener(configuration, xhr, 'error', function () {
    displayRemoteConfigurationFetchingError();
  });
  xhr.open('GET', `${REMOTE_CONFIGURATION_URL}/${encodeURIComponent(configuration.remoteConfigurationId)}.json`);
  xhr.send();
}
function displayRemoteConfigurationFetchingError() {
  display.error('Error fetching the remote configuration.');
}

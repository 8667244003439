import { trackFirstContentfulPaint } from './trackFirstContentfulPaint';
import { trackFirstInput } from './trackFirstInput';
import { trackNavigationTimings } from './trackNavigationTimings';
import { trackLargestContentfulPaint } from './trackLargestContentfulPaint';
import { trackFirstHidden } from './trackFirstHidden';
export function trackInitialViewMetrics(configuration, setLoadEvent, scheduleViewUpdate) {
  const initialViewMetrics = {};
  const {
    stop: stopNavigationTracking
  } = trackNavigationTimings(configuration, navigationTimings => {
    setLoadEvent(navigationTimings.loadEvent);
    initialViewMetrics.navigationTimings = navigationTimings;
    scheduleViewUpdate();
  });
  const firstHidden = trackFirstHidden(configuration);
  const {
    stop: stopFCPTracking
  } = trackFirstContentfulPaint(configuration, firstHidden, firstContentfulPaint => {
    initialViewMetrics.firstContentfulPaint = firstContentfulPaint;
    scheduleViewUpdate();
  });
  const {
    stop: stopLCPTracking
  } = trackLargestContentfulPaint(configuration, firstHidden, window, largestContentfulPaint => {
    initialViewMetrics.largestContentfulPaint = largestContentfulPaint;
    scheduleViewUpdate();
  });
  const {
    stop: stopFIDTracking
  } = trackFirstInput(configuration, firstHidden, firstInput => {
    initialViewMetrics.firstInput = firstInput;
    scheduleViewUpdate();
  });
  function stop() {
    stopNavigationTracking();
    stopFCPTracking();
    stopLCPTracking();
    stopFIDTracking();
    firstHidden.stop();
  }
  return {
    stop,
    initialViewMetrics
  };
}

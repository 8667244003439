export function findLast(array, predicate) {
  for (let i = array.length - 1; i >= 0; i -= 1) {
    const item = array[i];
    if (predicate(item, i, array)) {
      return item;
    }
  }
  return undefined;
}
// Keep the following wrapper functions as it can be mangled and will result in smaller bundle size that using
// the native Object.values and Object.entries directly
export function objectValues(object) {
  return Object.values(object);
}
export function objectEntries(object) {
  return Object.entries(object);
}

import { setTimeout, clearTimeout } from '../timer';
// use lodash API
export function throttle(fn, wait, options) {
  const needLeadingExecution = options && options.leading !== undefined ? options.leading : true;
  const needTrailingExecution = options && options.trailing !== undefined ? options.trailing : true;
  let inWaitPeriod = false;
  let pendingExecutionWithParameters;
  let pendingTimeoutId;
  return {
    throttled: (...parameters) => {
      if (inWaitPeriod) {
        pendingExecutionWithParameters = parameters;
        return;
      }
      if (needLeadingExecution) {
        fn(...parameters);
      } else {
        pendingExecutionWithParameters = parameters;
      }
      inWaitPeriod = true;
      pendingTimeoutId = setTimeout(() => {
        if (needTrailingExecution && pendingExecutionWithParameters) {
          fn(...pendingExecutionWithParameters);
        }
        inWaitPeriod = false;
        pendingExecutionWithParameters = undefined;
      }, wait);
    },
    cancel: () => {
      clearTimeout(pendingTimeoutId);
      inWaitPeriod = false;
      pendingExecutionWithParameters = undefined;
    }
  };
}
// eslint-disable-next-line @typescript-eslint/no-empty-function
export function noop() {}

export function callPluginsMethod(plugins, methodName, parameter) {
  if (!plugins) {
    return;
  }
  for (const plugin of plugins) {
    const method = plugin[methodName];
    if (method) {
      method(parameter);
    }
  }
}

import { getInitCookie } from '../../browser/cookie';
export const SYNTHETICS_TEST_ID_COOKIE_NAME = 'datadog-synthetics-public-id';
export const SYNTHETICS_RESULT_ID_COOKIE_NAME = 'datadog-synthetics-result-id';
export const SYNTHETICS_INJECTS_RUM_COOKIE_NAME = 'datadog-synthetics-injects-rum';
export function willSyntheticsInjectRum() {
  return Boolean(window._DATADOG_SYNTHETICS_INJECTS_RUM || getInitCookie(SYNTHETICS_INJECTS_RUM_COOKIE_NAME));
}
export function getSyntheticsTestId() {
  const value = window._DATADOG_SYNTHETICS_PUBLIC_ID || getInitCookie(SYNTHETICS_TEST_ID_COOKIE_NAME);
  return typeof value === 'string' ? value : undefined;
}
export function getSyntheticsResultId() {
  const value = window._DATADOG_SYNTHETICS_RESULT_ID || getInitCookie(SYNTHETICS_RESULT_ID_COOKIE_NAME);
  return typeof value === 'string' ? value : undefined;
}

import { createEndpointBuilder } from './endpointBuilder';
import { buildTags } from './tags';
import { INTAKE_SITE_US1, INTAKE_URL_PARAMETERS } from './intakeSites';
export function computeTransportConfiguration(initConfiguration) {
  const site = initConfiguration.site || INTAKE_SITE_US1;
  const tags = buildTags(initConfiguration);
  const endpointBuilders = computeEndpointBuilders(initConfiguration, tags);
  const replicaConfiguration = computeReplicaConfiguration(initConfiguration, tags);
  return {
    replica: replicaConfiguration,
    site,
    ...endpointBuilders
  };
}
function computeEndpointBuilders(initConfiguration, tags) {
  return {
    logsEndpointBuilder: createEndpointBuilder(initConfiguration, 'logs', tags),
    rumEndpointBuilder: createEndpointBuilder(initConfiguration, 'rum', tags),
    sessionReplayEndpointBuilder: createEndpointBuilder(initConfiguration, 'replay', tags)
  };
}
function computeReplicaConfiguration(initConfiguration, tags) {
  if (!initConfiguration.replica) {
    return;
  }
  const replicaConfiguration = {
    ...initConfiguration,
    site: INTAKE_SITE_US1,
    clientToken: initConfiguration.replica.clientToken
  };
  const replicaEndpointBuilders = {
    logsEndpointBuilder: createEndpointBuilder(replicaConfiguration, 'logs', tags),
    rumEndpointBuilder: createEndpointBuilder(replicaConfiguration, 'rum', tags)
  };
  return {
    applicationId: initConfiguration.replica.applicationId,
    ...replicaEndpointBuilders
  };
}
export function isIntakeUrl(url) {
  // check if tags is present in the query string
  return INTAKE_URL_PARAMETERS.every(param => url.includes(param));
}

import { monitor } from '../tools/monitor';
import { getZoneJsOriginalValue } from '../tools/getZoneJsOriginalValue';
/**
 * Add an event listener to an event target object (Window, Element, mock object...).  This provides
 * a few conveniences compared to using `element.addEventListener` directly:
 *
 * * supports IE11 by: using an option object only if needed and emulating the `once` option
 *
 * * wraps the listener with a `monitor` function
 *
 * * returns a `stop` function to remove the listener
 */
export function addEventListener(configuration, eventTarget, eventName, listener, options) {
  return addEventListeners(configuration, eventTarget, [eventName], listener, options);
}
/**
 * Add event listeners to an event target object (Window, Element, mock object...).  This provides
 * a few conveniences compared to using `element.addEventListener` directly:
 *
 * * supports IE11 by: using an option object only if needed and emulating the `once` option
 *
 * * wraps the listener with a `monitor` function
 *
 * * returns a `stop` function to remove the listener
 *
 * * with `once: true`, the listener will be called at most once, even if different events are listened
 */
export function addEventListeners(configuration, eventTarget, eventNames, listener, {
  once,
  capture,
  passive
} = {}) {
  const listenerWithMonitor = monitor(event => {
    if (!event.isTrusted && !event.__ddIsTrusted && !configuration.allowUntrustedEvents) {
      return;
    }
    if (once) {
      stop();
    }
    listener(event);
  });
  const options = passive ? {
    capture,
    passive
  } : capture;
  // Use the window.EventTarget.prototype when possible to avoid wrong overrides (e.g: https://github.com/salesforce/lwc/issues/1824)
  const listenerTarget = window.EventTarget && eventTarget instanceof EventTarget ? window.EventTarget.prototype : eventTarget;
  const add = getZoneJsOriginalValue(listenerTarget, 'addEventListener');
  eventNames.forEach(eventName => add.call(eventTarget, eventName, listenerWithMonitor, options));
  function stop() {
    const remove = getZoneJsOriginalValue(listenerTarget, 'removeEventListener');
    eventNames.forEach(eventName => remove.call(eventTarget, eventName, listenerWithMonitor, options));
  }
  return {
    stop
  };
}

// eslint-disable-next-line no-restricted-syntax
export class AbstractLifeCycle {
  constructor() {
    this.callbacks = {};
  }
  notify(eventType, data) {
    const eventCallbacks = this.callbacks[eventType];
    if (eventCallbacks) {
      eventCallbacks.forEach(callback => callback(data));
    }
  }
  subscribe(eventType, callback) {
    if (!this.callbacks[eventType]) {
      this.callbacks[eventType] = [];
    }
    this.callbacks[eventType].push(callback);
    return {
      unsubscribe: () => {
        this.callbacks[eventType] = this.callbacks[eventType].filter(other => callback !== other);
      }
    };
  }
}

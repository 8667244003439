import { jsonStringify } from '../serialisation/jsonStringify';
export function normalizeUrl(url) {
  return buildUrl(url, location.href).href;
}
export function isValidUrl(url) {
  try {
    return !!buildUrl(url);
  } catch (_a) {
    return false;
  }
}
export function getPathName(url) {
  const pathname = buildUrl(url).pathname;
  return pathname[0] === '/' ? pathname : `/${pathname}`;
}
export function buildUrl(url, base) {
  const supportedURL = getSupportedUrl();
  if (supportedURL) {
    try {
      return base !== undefined ? new supportedURL(url, base) : new supportedURL(url);
    } catch (error) {
      throw new Error(`Failed to construct URL: ${String(error)} ${jsonStringify({
        url,
        base
      })}`);
    }
  }
  if (base === undefined && !/:/.test(url)) {
    throw new Error(`Invalid URL: '${url}'`);
  }
  let doc = document;
  const anchorElement = doc.createElement('a');
  if (base !== undefined) {
    doc = document.implementation.createHTMLDocument('');
    const baseElement = doc.createElement('base');
    baseElement.href = base;
    doc.head.appendChild(baseElement);
    doc.body.appendChild(anchorElement);
  }
  anchorElement.href = url;
  return anchorElement;
}
const originalURL = URL;
let isURLSupported;
function getSupportedUrl() {
  if (isURLSupported === undefined) {
    try {
      const url = new originalURL('http://test/path');
      isURLSupported = url.href === 'http://test/path';
    } catch (_a) {
      isURLSupported = false;
    }
  }
  return isURLSupported ? originalURL : undefined;
}

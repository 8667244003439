import { generateUUID } from '../../../tools/utils/stringUtils';
import { SessionPersistence } from '../sessionConstants';
import { toSessionString, toSessionState, getExpiredSessionState } from '../sessionState';
import { SESSION_STORE_KEY } from './sessionStoreStrategy';
const LOCAL_STORAGE_TEST_KEY = '_dd_test_';
export function selectLocalStorageStrategy() {
  try {
    const id = generateUUID();
    const testKey = `${LOCAL_STORAGE_TEST_KEY}${id}`;
    localStorage.setItem(testKey, id);
    const retrievedId = localStorage.getItem(testKey);
    localStorage.removeItem(testKey);
    return id === retrievedId ? {
      type: SessionPersistence.LOCAL_STORAGE
    } : undefined;
  } catch (_a) {
    return undefined;
  }
}
export function initLocalStorageStrategy(configuration) {
  return {
    isLockEnabled: false,
    persistSession: persistInLocalStorage,
    retrieveSession: retrieveSessionFromLocalStorage,
    expireSession: sessionState => expireSessionFromLocalStorage(sessionState, configuration)
  };
}
function persistInLocalStorage(sessionState) {
  localStorage.setItem(SESSION_STORE_KEY, toSessionString(sessionState));
}
function retrieveSessionFromLocalStorage() {
  const sessionString = localStorage.getItem(SESSION_STORE_KEY);
  return toSessionState(sessionString);
}
function expireSessionFromLocalStorage(previousSessionState, configuration) {
  persistInLocalStorage(getExpiredSessionState(previousSessionState, configuration));
}

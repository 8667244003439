import { addTelemetryError } from '../domain/telemetry';
import { monitor } from '../tools/monitor';
import { addEventListener } from '../browser/addEventListener';
import { newRetryState, sendWithRetryStrategy } from './sendWithRetryStrategy';
export function createHttpRequest(endpointBuilder, bytesLimit, reportError) {
  const retryState = newRetryState();
  const sendStrategyForRetry = (payload, onResponse) => fetchKeepAliveStrategy(endpointBuilder, bytesLimit, payload, onResponse);
  return {
    send: payload => {
      sendWithRetryStrategy(payload, retryState, sendStrategyForRetry, endpointBuilder.trackType, reportError);
    },
    /**
     * Since fetch keepalive behaves like regular fetch on Firefox,
     * keep using sendBeaconStrategy on exit
     */
    sendOnExit: payload => {
      sendBeaconStrategy(endpointBuilder, bytesLimit, payload);
    }
  };
}
function sendBeaconStrategy(endpointBuilder, bytesLimit, payload) {
  const canUseBeacon = !!navigator.sendBeacon && payload.bytesCount < bytesLimit;
  if (canUseBeacon) {
    try {
      const beaconUrl = endpointBuilder.build('beacon', payload);
      const isQueued = navigator.sendBeacon(beaconUrl, payload.data);
      if (isQueued) {
        return;
      }
    } catch (e) {
      reportBeaconError(e);
    }
  }
  const xhrUrl = endpointBuilder.build('xhr', payload);
  sendXHR(xhrUrl, payload.data);
}
let hasReportedBeaconError = false;
function reportBeaconError(e) {
  if (!hasReportedBeaconError) {
    hasReportedBeaconError = true;
    addTelemetryError(e);
  }
}
export function fetchKeepAliveStrategy(endpointBuilder, bytesLimit, payload, onResponse) {
  const canUseKeepAlive = isKeepAliveSupported() && payload.bytesCount < bytesLimit;
  if (canUseKeepAlive) {
    const fetchUrl = endpointBuilder.build('fetch', payload);
    fetch(fetchUrl, {
      method: 'POST',
      body: payload.data,
      keepalive: true,
      mode: 'cors'
    }).then(monitor(response => onResponse === null || onResponse === void 0 ? void 0 : onResponse({
      status: response.status,
      type: response.type
    })), monitor(() => {
      const xhrUrl = endpointBuilder.build('xhr', payload);
      // failed to queue the request
      sendXHR(xhrUrl, payload.data, onResponse);
    }));
  } else {
    const xhrUrl = endpointBuilder.build('xhr', payload);
    sendXHR(xhrUrl, payload.data, onResponse);
  }
}
function isKeepAliveSupported() {
  // Request can throw, cf https://developer.mozilla.org/en-US/docs/Web/API/Request/Request#errors
  try {
    return window.Request && 'keepalive' in new Request('http://a');
  } catch (_a) {
    return false;
  }
}
export function sendXHR(url, data, onResponse) {
  const request = new XMLHttpRequest();
  request.open('POST', url, true);
  if (data instanceof Blob) {
    // When using a Blob instance, IE does not use its 'type' to define the 'Content-Type' header
    // automatically, so the intake request ends up being rejected with an HTTP status 415
    // Defining the header manually fixes this issue.
    request.setRequestHeader('Content-Type', data.type);
  }
  addEventListener(
  // allow untrusted event to acount for synthetic event dispatched by third party xhr wrapper
  {
    allowUntrustedEvents: true
  }, request, 'loadend', () => {
    onResponse === null || onResponse === void 0 ? void 0 : onResponse({
      status: request.status
    });
  }, {
    // prevent multiple onResponse callbacks
    // if the xhr instance is reused by a third party
    once: true
  });
  request.send(data);
}

import { deepClone } from '../../tools/mergeInto';
import { getType } from '../../tools/utils/typeUtils';
import { sanitize } from '../../tools/serialisation/sanitize';
import { Observable } from '../../tools/observable';
export function createContextManager(customerDataTracker) {
  let context = {};
  const changeObservable = new Observable();
  const contextManager = {
    getContext: () => deepClone(context),
    setContext: newContext => {
      if (getType(newContext) === 'object') {
        context = sanitize(newContext);
        customerDataTracker === null || customerDataTracker === void 0 ? void 0 : customerDataTracker.updateCustomerData(context);
      } else {
        contextManager.clearContext();
      }
      changeObservable.notify();
    },
    setContextProperty: (key, property) => {
      context[key] = sanitize(property);
      customerDataTracker === null || customerDataTracker === void 0 ? void 0 : customerDataTracker.updateCustomerData(context);
      changeObservable.notify();
    },
    removeContextProperty: key => {
      delete context[key];
      customerDataTracker === null || customerDataTracker === void 0 ? void 0 : customerDataTracker.updateCustomerData(context);
      changeObservable.notify();
    },
    clearContext: () => {
      context = {};
      customerDataTracker === null || customerDataTracker === void 0 ? void 0 : customerDataTracker.resetCustomerData();
      changeObservable.notify();
    },
    changeObservable
  };
  return contextManager;
}

import { flattenErrorCauses, isError, tryToGetFingerprint } from '../error/error';
import { mergeObservables, Observable } from '../../tools/observable';
import { ConsoleApiName, globalConsole } from '../../tools/display';
import { callMonitored } from '../../tools/monitor';
import { sanitize } from '../../tools/serialisation/sanitize';
import { jsonStringify } from '../../tools/serialisation/jsonStringify';
import { ErrorSource } from '../error/error.types';
import { computeStackTrace } from '../../tools/stackTrace/computeStackTrace';
import { createHandlingStack, toStackTraceString, formatErrorMessage } from '../../tools/stackTrace/handlingStack';
import { clocksNow } from '../../tools/utils/timeUtils';
let consoleObservablesByApi = {};
export function initConsoleObservable(apis) {
  const consoleObservables = apis.map(api => {
    if (!consoleObservablesByApi[api]) {
      consoleObservablesByApi[api] = createConsoleObservable(api); // we are sure that the observable created for this api will yield the expected ConsoleLog type
    }
    return consoleObservablesByApi[api];
  });
  return mergeObservables(...consoleObservables);
}
export function resetConsoleObservable() {
  consoleObservablesByApi = {};
}
function createConsoleObservable(api) {
  return new Observable(observable => {
    const originalConsoleApi = globalConsole[api];
    globalConsole[api] = (...params) => {
      originalConsoleApi.apply(console, params);
      const handlingStack = createHandlingStack();
      callMonitored(() => {
        observable.notify(buildConsoleLog(params, api, handlingStack));
      });
    };
    return () => {
      globalConsole[api] = originalConsoleApi;
    };
  });
}
function buildConsoleLog(params, api, handlingStack) {
  const message = params.map(param => formatConsoleParameters(param)).join(' ');
  let error;
  if (api === ConsoleApiName.error) {
    const firstErrorParam = params.find(isError);
    error = {
      stack: firstErrorParam ? toStackTraceString(computeStackTrace(firstErrorParam)) : undefined,
      fingerprint: tryToGetFingerprint(firstErrorParam),
      causes: firstErrorParam ? flattenErrorCauses(firstErrorParam, 'console') : undefined,
      startClocks: clocksNow(),
      message,
      source: ErrorSource.CONSOLE,
      handling: "handled" /* ErrorHandling.HANDLED */,
      handlingStack
    };
  }
  return {
    api,
    message,
    error,
    handlingStack
  };
}
function formatConsoleParameters(param) {
  if (typeof param === 'string') {
    return sanitize(param);
  }
  if (isError(param)) {
    return formatErrorMessage(computeStackTrace(param));
  }
  return jsonStringify(sanitize(param), undefined, 2);
}

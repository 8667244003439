import { Observable } from '../../tools/observable';
import { createValueHistory } from '../../tools/valueHistory';
import { relativeNow, clocksOrigin, ONE_MINUTE } from '../../tools/utils/timeUtils';
import { addEventListener, addEventListeners } from '../../browser/addEventListener';
import { clearInterval, setInterval } from '../../tools/timer';
import { SESSION_TIME_OUT_DELAY } from './sessionConstants';
import { startSessionStore } from './sessionStore';
export const VISIBILITY_CHECK_DELAY = ONE_MINUTE;
const SESSION_CONTEXT_TIMEOUT_DELAY = SESSION_TIME_OUT_DELAY;
let stopCallbacks = [];
export function startSessionManager(configuration, productKey, computeSessionState, trackingConsentState) {
  const renewObservable = new Observable();
  const expireObservable = new Observable();
  // TODO - Improve configuration type and remove assertion
  const sessionStore = startSessionStore(configuration.sessionStoreStrategyType, configuration, productKey, computeSessionState);
  stopCallbacks.push(() => sessionStore.stop());
  const sessionContextHistory = createValueHistory({
    expireDelay: SESSION_CONTEXT_TIMEOUT_DELAY
  });
  stopCallbacks.push(() => sessionContextHistory.stop());
  sessionStore.renewObservable.subscribe(() => {
    sessionContextHistory.add(buildSessionContext(), relativeNow());
    renewObservable.notify();
  });
  sessionStore.expireObservable.subscribe(() => {
    expireObservable.notify();
    sessionContextHistory.closeActive(relativeNow());
  });
  // We expand/renew session unconditionally as tracking consent is always granted when the session
  // manager is started.
  sessionStore.expandOrRenewSession();
  sessionContextHistory.add(buildSessionContext(), clocksOrigin().relative);
  trackingConsentState.observable.subscribe(() => {
    if (trackingConsentState.isGranted()) {
      sessionStore.expandOrRenewSession();
    } else {
      sessionStore.expire();
    }
  });
  trackActivity(configuration, () => {
    if (trackingConsentState.isGranted()) {
      sessionStore.expandOrRenewSession();
    }
  });
  trackVisibility(configuration, () => sessionStore.expandSession());
  trackResume(configuration, () => sessionStore.restartSession());
  function buildSessionContext() {
    return {
      id: sessionStore.getSession().id,
      trackingType: sessionStore.getSession()[productKey],
      isReplayForced: !!sessionStore.getSession().forcedReplay,
      anonymousId: sessionStore.getSession().anonymousId
    };
  }
  return {
    findSession: (startTime, options) => sessionContextHistory.find(startTime, options),
    renewObservable,
    expireObservable,
    sessionStateUpdateObservable: sessionStore.sessionStateUpdateObservable,
    expire: sessionStore.expire,
    updateSessionState: sessionStore.updateSessionState
  };
}
export function stopSessionManager() {
  stopCallbacks.forEach(e => e());
  stopCallbacks = [];
}
function trackActivity(configuration, expandOrRenewSession) {
  const {
    stop
  } = addEventListeners(configuration, window, ["click" /* DOM_EVENT.CLICK */, "touchstart" /* DOM_EVENT.TOUCH_START */, "keydown" /* DOM_EVENT.KEY_DOWN */, "scroll" /* DOM_EVENT.SCROLL */], expandOrRenewSession, {
    capture: true,
    passive: true
  });
  stopCallbacks.push(stop);
}
function trackVisibility(configuration, expandSession) {
  const expandSessionWhenVisible = () => {
    if (document.visibilityState === 'visible') {
      expandSession();
    }
  };
  const {
    stop
  } = addEventListener(configuration, document, "visibilitychange" /* DOM_EVENT.VISIBILITY_CHANGE */, expandSessionWhenVisible);
  stopCallbacks.push(stop);
  const visibilityCheckInterval = setInterval(expandSessionWhenVisible, VISIBILITY_CHECK_DELAY);
  stopCallbacks.push(() => {
    clearInterval(visibilityCheckInterval);
  });
}
function trackResume(configuration, cb) {
  const {
    stop
  } = addEventListener(configuration, window, "resume" /* DOM_EVENT.RESUME */, cb, {
    capture: true
  });
  stopCallbacks.push(stop);
}

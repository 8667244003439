import { addEventListener } from '@datadog/browser-core';
export function listenActionEvents(configuration, {
  onPointerDown,
  onPointerUp
}) {
  let selectionEmptyAtPointerDown;
  let userActivity = {
    selection: false,
    input: false,
    scroll: false
  };
  let clickContext;
  const listeners = [addEventListener(configuration, window, "pointerdown" /* DOM_EVENT.POINTER_DOWN */, event => {
    if (isValidPointerEvent(event)) {
      selectionEmptyAtPointerDown = isSelectionEmpty();
      userActivity = {
        selection: false,
        input: false,
        scroll: false
      };
      clickContext = onPointerDown(event);
    }
  }, {
    capture: true
  }), addEventListener(configuration, window, "selectionchange" /* DOM_EVENT.SELECTION_CHANGE */, () => {
    if (!selectionEmptyAtPointerDown || !isSelectionEmpty()) {
      userActivity.selection = true;
    }
  }, {
    capture: true
  }), addEventListener(configuration, window, "scroll" /* DOM_EVENT.SCROLL */, () => {
    userActivity.scroll = true;
  }, {
    capture: true,
    passive: true
  }), addEventListener(configuration, window, "pointerup" /* DOM_EVENT.POINTER_UP */, event => {
    if (isValidPointerEvent(event) && clickContext) {
      // Use a scoped variable to make sure the value is not changed by other clicks
      const localUserActivity = userActivity;
      onPointerUp(clickContext, event, () => localUserActivity);
      clickContext = undefined;
    }
  }, {
    capture: true
  }), addEventListener(configuration, window, "input" /* DOM_EVENT.INPUT */, () => {
    userActivity.input = true;
  }, {
    capture: true
  })];
  return {
    stop: () => {
      listeners.forEach(listener => listener.stop());
    }
  };
}
function isSelectionEmpty() {
  const selection = window.getSelection();
  return !selection || selection.isCollapsed;
}
function isValidPointerEvent(event) {
  return event.target instanceof Element &&
  // Only consider 'primary' pointer events for now. Multi-touch support could be implemented in
  // the future.
  event.isPrimary !== false;
}

import { ExperimentalFeature, isExperimentalFeatureEnabled } from '@datadog/browser-core';
import { getViewportDimension, initViewportObservable } from '../../browser/viewportObservable';
export function startDisplayContext(configuration) {
  let viewport;
  let animationFrameId;
  if (isExperimentalFeatureEnabled(ExperimentalFeature.DELAY_VIEWPORT_COLLECTION)) {
    // Use requestAnimationFrame to delay the calculation of viewport dimensions until after SDK initialization, preventing long tasks.
    animationFrameId = requestAnimationFrame(() => {
      viewport = getViewportDimension();
    });
  } else {
    viewport = getViewportDimension();
  }
  const unsubscribeViewport = initViewportObservable(configuration).subscribe(viewportDimension => {
    viewport = viewportDimension;
  }).unsubscribe;
  return {
    get: () => viewport ? {
      viewport
    } : undefined,
    stop: () => {
      unsubscribeViewport();
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
      }
    }
  };
}

import { SESSION_TIME_OUT_DELAY, createValueHistory } from '@datadog/browser-core';
export const VIEW_CONTEXT_TIME_OUT_DELAY = SESSION_TIME_OUT_DELAY;
export function startViewHistory(lifeCycle) {
  const viewValueHistory = createValueHistory({
    expireDelay: VIEW_CONTEXT_TIME_OUT_DELAY
  });
  lifeCycle.subscribe(1 /* LifeCycleEventType.BEFORE_VIEW_CREATED */, view => {
    viewValueHistory.add(buildViewHistoryEntry(view), view.startClocks.relative);
  });
  lifeCycle.subscribe(5 /* LifeCycleEventType.AFTER_VIEW_ENDED */, ({
    endClocks
  }) => {
    viewValueHistory.closeActive(endClocks.relative);
  });
  lifeCycle.subscribe(3 /* LifeCycleEventType.VIEW_UPDATED */, viewUpdate => {
    const currentView = viewValueHistory.find(viewUpdate.startClocks.relative);
    if (currentView && viewUpdate.name) {
      currentView.name = viewUpdate.name;
    }
    if (currentView && viewUpdate.context) {
      currentView.context = viewUpdate.context;
    }
  });
  lifeCycle.subscribe(9 /* LifeCycleEventType.SESSION_RENEWED */, () => {
    viewValueHistory.reset();
  });
  function buildViewHistoryEntry(view) {
    return {
      service: view.service,
      version: view.version,
      context: view.context,
      id: view.id,
      name: view.name,
      startClocks: view.startClocks
    };
  }
  return {
    findView: startTime => viewValueHistory.find(startTime),
    stop: () => {
      viewValueHistory.stop();
    }
  };
}

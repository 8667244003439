import { trackEventCounts } from '../trackEventCounts';
export function trackViewEventCounts(lifeCycle, viewId, onChange) {
  const {
    stop,
    eventCounts
  } = trackEventCounts({
    lifeCycle,
    isChildEvent: event => event.view.id === viewId,
    onChange
  });
  return {
    stop,
    eventCounts
  };
}

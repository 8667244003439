import { instrumentMethod } from '../../tools/instrumentMethod';
import { clocksNow } from '../../tools/utils/timeUtils';
import { computeStackTrace, computeStackTraceFromOnErrorMessage } from '../../tools/stackTrace/computeStackTrace';
import { computeRawError, isError } from './error';
import { ErrorSource } from './error.types';
export function trackRuntimeError(errorObservable) {
  const handleRuntimeError = (stackTrace, originalError) => {
    const rawError = computeRawError({
      stackTrace,
      originalError,
      startClocks: clocksNow(),
      nonErrorPrefix: "Uncaught" /* NonErrorPrefix.UNCAUGHT */,
      source: ErrorSource.SOURCE,
      handling: "unhandled" /* ErrorHandling.UNHANDLED */
    });
    errorObservable.notify(rawError);
  };
  const {
    stop: stopInstrumentingOnError
  } = instrumentOnError(handleRuntimeError);
  const {
    stop: stopInstrumentingOnUnhandledRejection
  } = instrumentUnhandledRejection(handleRuntimeError);
  return {
    stop: () => {
      stopInstrumentingOnError();
      stopInstrumentingOnUnhandledRejection();
    }
  };
}
export function instrumentOnError(callback) {
  return instrumentMethod(window, 'onerror', ({
    parameters: [messageObj, url, line, column, errorObj]
  }) => {
    let stackTrace;
    if (isError(errorObj)) {
      stackTrace = computeStackTrace(errorObj);
    } else {
      stackTrace = computeStackTraceFromOnErrorMessage(messageObj, url, line, column);
    }
    callback(stackTrace, errorObj !== null && errorObj !== void 0 ? errorObj : messageObj);
  });
}
export function instrumentUnhandledRejection(callback) {
  return instrumentMethod(window, 'onunhandledrejection', ({
    parameters: [e]
  }) => {
    const reason = e.reason || 'Empty reason';
    const stack = computeStackTrace(reason);
    callback(stack, reason);
  });
}

export function isChromium() {
  return detectBrowserCached() === 0 /* Browser.CHROMIUM */;
}
export function isSafari() {
  return detectBrowserCached() === 1 /* Browser.SAFARI */;
}
let browserCache;
function detectBrowserCached() {
  return browserCache !== null && browserCache !== void 0 ? browserCache : browserCache = detectBrowser();
}
// Exported only for tests
export function detectBrowser(browserWindow = window) {
  var _a;
  const userAgent = browserWindow.navigator.userAgent;
  if (browserWindow.chrome || /HeadlessChrome/.test(userAgent)) {
    return 0 /* Browser.CHROMIUM */;
  }
  if (
  // navigator.vendor is deprecated, but it is the most resilient way we found to detect
  // "Apple maintained browsers" (AKA Safari). If one day it gets removed, we still have the
  // useragent test as a semi-working fallback.
  ((_a = browserWindow.navigator.vendor) === null || _a === void 0 ? void 0 : _a.indexOf('Apple')) === 0 || /safari/i.test(userAgent) && !/chrome|android/i.test(userAgent)) {
    return 1 /* Browser.SAFARI */;
  }
  return 2 /* Browser.OTHER */;
}

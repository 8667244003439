import { instrumentMethod } from '../tools/instrumentMethod';
import { Observable } from '../tools/observable';
import { elapsed, clocksNow, timeStampNow } from '../tools/utils/timeUtils';
import { normalizeUrl } from '../tools/utils/urlPolyfill';
import { shallowClone } from '../tools/utils/objectUtils';
import { addEventListener } from './addEventListener';
let xhrObservable;
const xhrContexts = new WeakMap();
export function initXhrObservable(configuration) {
  if (!xhrObservable) {
    xhrObservable = createXhrObservable(configuration);
  }
  return xhrObservable;
}
function createXhrObservable(configuration) {
  return new Observable(observable => {
    const {
      stop: stopInstrumentingStart
    } = instrumentMethod(XMLHttpRequest.prototype, 'open', openXhr);
    const {
      stop: stopInstrumentingSend
    } = instrumentMethod(XMLHttpRequest.prototype, 'send', call => {
      sendXhr(call, configuration, observable);
    }, {
      computeHandlingStack: true
    });
    const {
      stop: stopInstrumentingAbort
    } = instrumentMethod(XMLHttpRequest.prototype, 'abort', abortXhr);
    return () => {
      stopInstrumentingStart();
      stopInstrumentingSend();
      stopInstrumentingAbort();
    };
  });
}
function openXhr({
  target: xhr,
  parameters: [method, url]
}) {
  xhrContexts.set(xhr, {
    state: 'open',
    method: String(method).toUpperCase(),
    url: normalizeUrl(String(url))
  });
}
function sendXhr({
  target: xhr,
  handlingStack
}, configuration, observable) {
  const context = xhrContexts.get(xhr);
  if (!context) {
    return;
  }
  const startContext = context;
  startContext.state = 'start';
  startContext.startClocks = clocksNow();
  startContext.isAborted = false;
  startContext.xhr = xhr;
  startContext.handlingStack = handlingStack;
  let hasBeenReported = false;
  const {
    stop: stopInstrumentingOnReadyStateChange
  } = instrumentMethod(xhr, 'onreadystatechange', () => {
    if (xhr.readyState === XMLHttpRequest.DONE) {
      // Try to report the XHR as soon as possible, because the XHR may be mutated by the
      // application during a future event. For example, Angular is calling .abort() on
      // completed requests during an onreadystatechange event, so the status becomes '0'
      // before the request is collected.
      onEnd();
    }
  });
  const onEnd = () => {
    unsubscribeLoadEndListener();
    stopInstrumentingOnReadyStateChange();
    if (hasBeenReported) {
      return;
    }
    hasBeenReported = true;
    const completeContext = context;
    completeContext.state = 'complete';
    completeContext.duration = elapsed(startContext.startClocks.timeStamp, timeStampNow());
    completeContext.status = xhr.status;
    observable.notify(shallowClone(completeContext));
  };
  const {
    stop: unsubscribeLoadEndListener
  } = addEventListener(configuration, xhr, 'loadend', onEnd);
  observable.notify(startContext);
}
function abortXhr({
  target: xhr
}) {
  const context = xhrContexts.get(xhr);
  if (context) {
    context.isAborted = true;
  }
}

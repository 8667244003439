import { removeItem } from './utils/arrayUtils';
const BUFFER_LIMIT = 500;
export function createBoundedBuffer() {
  const buffer = [];
  const add = callback => {
    const length = buffer.push(callback);
    if (length > BUFFER_LIMIT) {
      buffer.splice(0, 1);
    }
  };
  const remove = callback => {
    removeItem(buffer, callback);
  };
  const drain = arg => {
    buffer.forEach(callback => callback(arg));
    buffer.length = 0;
  };
  return {
    add,
    remove,
    drain
  };
}

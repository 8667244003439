import { runOnReadyState } from '@datadog/browser-core';
import { RumPerformanceEntryType } from '../../browser/performanceObservable';
import { getDocumentTraceId } from '../tracing/getDocumentTraceId';
import { getNavigationEntry } from '../../browser/performanceUtils';
import { FAKE_INITIAL_DOCUMENT } from './resourceUtils';
export function retrieveInitialDocumentResourceTiming(configuration, callback) {
  runOnReadyState(configuration, 'interactive', () => {
    const entry = Object.assign(getNavigationEntry().toJSON(), {
      entryType: RumPerformanceEntryType.RESOURCE,
      initiatorType: FAKE_INITIAL_DOCUMENT,
      traceId: getDocumentTraceId(document),
      toJSON: () => ({
        ...entry,
        toJSON: undefined
      })
    });
    callback(entry);
  });
}

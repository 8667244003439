import { timeStampNow, Observable, getRelativeTime, ONE_MINUTE, generateUUID, clocksNow, elapsed, createValueHistory } from '@datadog/browser-core';
import { trackEventCounts } from '../trackEventCounts';
import { PAGE_ACTIVITY_VALIDATION_DELAY, waitPageActivityEnd } from '../waitPageActivityEnd';
import { getSelectorFromElement } from '../getSelectorFromElement';
import { getNodePrivacyLevel, NodePrivacyLevel } from '../privacy';
import { createClickChain } from './clickChain';
import { getActionNameFromElement } from './getActionNameFromElement';
import { listenActionEvents } from './listenActionEvents';
import { computeFrustration } from './computeFrustration';
import { CLICK_ACTION_MAX_DURATION, updateInteractionSelector } from './interactionSelectorCache';
export const ACTION_CONTEXT_TIME_OUT_DELAY = 5 * ONE_MINUTE; // arbitrary
export function trackClickActions(lifeCycle, domMutationObservable, windowOpenObservable, configuration) {
  const history = createValueHistory({
    expireDelay: ACTION_CONTEXT_TIME_OUT_DELAY
  });
  const stopObservable = new Observable();
  let currentClickChain;
  lifeCycle.subscribe(9 /* LifeCycleEventType.SESSION_RENEWED */, () => {
    history.reset();
  });
  lifeCycle.subscribe(4 /* LifeCycleEventType.VIEW_ENDED */, stopClickChain);
  const {
    stop: stopActionEventsListener
  } = listenActionEvents(configuration, {
    onPointerDown: pointerDownEvent => processPointerDown(configuration, lifeCycle, domMutationObservable, pointerDownEvent, windowOpenObservable),
    onPointerUp: ({
      clickActionBase,
      hadActivityOnPointerDown
    }, startEvent, getUserActivity) => {
      startClickAction(configuration, lifeCycle, domMutationObservable, windowOpenObservable, history, stopObservable, appendClickToClickChain, clickActionBase, startEvent, getUserActivity, hadActivityOnPointerDown);
    }
  });
  const actionContexts = {
    findActionId: startTime => history.findAll(startTime)
  };
  return {
    stop: () => {
      stopClickChain();
      stopObservable.notify();
      stopActionEventsListener();
    },
    actionContexts
  };
  function appendClickToClickChain(click) {
    if (!currentClickChain || !currentClickChain.tryAppend(click)) {
      const rageClick = click.clone();
      currentClickChain = createClickChain(click, clicks => {
        finalizeClicks(clicks, rageClick);
      });
    }
  }
  function stopClickChain() {
    if (currentClickChain) {
      currentClickChain.stop();
    }
  }
}
function processPointerDown(configuration, lifeCycle, domMutationObservable, pointerDownEvent, windowOpenObservable) {
  const nodePrivacyLevel = configuration.enablePrivacyForActionName ? getNodePrivacyLevel(pointerDownEvent.target, configuration.defaultPrivacyLevel) : NodePrivacyLevel.ALLOW;
  if (nodePrivacyLevel === NodePrivacyLevel.HIDDEN) {
    return undefined;
  }
  const clickActionBase = computeClickActionBase(pointerDownEvent, nodePrivacyLevel, configuration);
  let hadActivityOnPointerDown = false;
  waitPageActivityEnd(lifeCycle, domMutationObservable, windowOpenObservable, configuration, pageActivityEndEvent => {
    hadActivityOnPointerDown = pageActivityEndEvent.hadActivity;
  },
  // We don't care about the activity duration, we just want to know whether an activity did happen
  // within the "validation delay" or not. Limit the duration so the callback is called sooner.
  PAGE_ACTIVITY_VALIDATION_DELAY);
  return {
    clickActionBase,
    hadActivityOnPointerDown: () => hadActivityOnPointerDown
  };
}
function startClickAction(configuration, lifeCycle, domMutationObservable, windowOpenObservable, history, stopObservable, appendClickToClickChain, clickActionBase, startEvent, getUserActivity, hadActivityOnPointerDown) {
  var _a;
  const click = newClick(lifeCycle, history, getUserActivity, clickActionBase, startEvent);
  appendClickToClickChain(click);
  const selector = (_a = clickActionBase === null || clickActionBase === void 0 ? void 0 : clickActionBase.target) === null || _a === void 0 ? void 0 : _a.selector;
  if (selector) {
    updateInteractionSelector(startEvent.timeStamp, selector);
  }
  const {
    stop: stopWaitPageActivityEnd
  } = waitPageActivityEnd(lifeCycle, domMutationObservable, windowOpenObservable, configuration, pageActivityEndEvent => {
    if (pageActivityEndEvent.hadActivity && pageActivityEndEvent.end < click.startClocks.timeStamp) {
      // If the clock is looking weird, just discard the click
      click.discard();
    } else {
      if (pageActivityEndEvent.hadActivity) {
        click.stop(pageActivityEndEvent.end);
      } else if (hadActivityOnPointerDown()) {
        click.stop(
        // using the click start as activity end, so the click will have some activity but its
        // duration will be 0 (as the activity started before the click start)
        click.startClocks.timeStamp);
      } else {
        click.stop();
      }
    }
  }, CLICK_ACTION_MAX_DURATION);
  const viewEndedSubscription = lifeCycle.subscribe(4 /* LifeCycleEventType.VIEW_ENDED */, ({
    endClocks
  }) => {
    click.stop(endClocks.timeStamp);
  });
  const stopSubscription = stopObservable.subscribe(() => {
    click.stop();
  });
  click.stopObservable.subscribe(() => {
    viewEndedSubscription.unsubscribe();
    stopWaitPageActivityEnd();
    stopSubscription.unsubscribe();
  });
}
function computeClickActionBase(event, nodePrivacyLevel, configuration) {
  const rect = event.target.getBoundingClientRect();
  const selector = getSelectorFromElement(event.target, configuration.actionNameAttribute);
  if (selector) {
    updateInteractionSelector(event.timeStamp, selector);
  }
  const actionName = getActionNameFromElement(event.target, configuration, nodePrivacyLevel);
  return {
    type: "click" /* ActionType.CLICK */,
    target: {
      width: Math.round(rect.width),
      height: Math.round(rect.height),
      selector
    },
    position: {
      // Use clientX and Y because for SVG element offsetX and Y are relatives to the <svg> element
      x: Math.round(event.clientX - rect.left),
      y: Math.round(event.clientY - rect.top)
    },
    name: actionName.name,
    nameSource: actionName.nameSource
  };
}
function newClick(lifeCycle, history, getUserActivity, clickActionBase, startEvent) {
  const id = generateUUID();
  const startClocks = clocksNow();
  const historyEntry = history.add(id, startClocks.relative);
  const eventCountsSubscription = trackEventCounts({
    lifeCycle,
    isChildEvent: event => event.action !== undefined && (Array.isArray(event.action.id) ? event.action.id.includes(id) : event.action.id === id)
  });
  let status = 0 /* ClickStatus.ONGOING */;
  let activityEndTime;
  const frustrationTypes = [];
  const stopObservable = new Observable();
  function stop(newActivityEndTime) {
    if (status !== 0 /* ClickStatus.ONGOING */) {
      return;
    }
    activityEndTime = newActivityEndTime;
    status = 1 /* ClickStatus.STOPPED */;
    if (activityEndTime) {
      historyEntry.close(getRelativeTime(activityEndTime));
    } else {
      historyEntry.remove();
    }
    eventCountsSubscription.stop();
    stopObservable.notify();
  }
  return {
    event: startEvent,
    stop,
    stopObservable,
    get hasError() {
      return eventCountsSubscription.eventCounts.errorCount > 0;
    },
    get hasPageActivity() {
      return activityEndTime !== undefined;
    },
    getUserActivity,
    addFrustration: frustrationType => {
      frustrationTypes.push(frustrationType);
    },
    startClocks,
    isStopped: () => status === 1 /* ClickStatus.STOPPED */ || status === 2 /* ClickStatus.FINALIZED */,
    clone: () => newClick(lifeCycle, history, getUserActivity, clickActionBase, startEvent),
    validate: domEvents => {
      stop();
      if (status !== 1 /* ClickStatus.STOPPED */) {
        return;
      }
      const {
        resourceCount,
        errorCount,
        longTaskCount
      } = eventCountsSubscription.eventCounts;
      const clickAction = {
        duration: activityEndTime && elapsed(startClocks.timeStamp, activityEndTime),
        startClocks,
        id,
        frustrationTypes,
        counts: {
          resourceCount,
          errorCount,
          longTaskCount
        },
        events: domEvents !== null && domEvents !== void 0 ? domEvents : [startEvent],
        event: startEvent,
        ...clickActionBase
      };
      lifeCycle.notify(0 /* LifeCycleEventType.AUTO_ACTION_COMPLETED */, clickAction);
      status = 2 /* ClickStatus.FINALIZED */;
    },
    discard: () => {
      stop();
      status = 2 /* ClickStatus.FINALIZED */;
    }
  };
}
export function finalizeClicks(clicks, rageClick) {
  const {
    isRage
  } = computeFrustration(clicks, rageClick);
  if (isRage) {
    clicks.forEach(click => click.discard());
    rageClick.stop(timeStampNow());
    rageClick.validate(clicks.map(click => click.event));
  } else {
    rageClick.discard();
    clicks.forEach(click => click.validate());
  }
}

export function shallowClone(object) {
  return {
    ...object
  };
}
export function objectHasValue(object, value) {
  return Object.keys(object).some(key => object[key] === value);
}
export function isEmptyObject(object) {
  return Object.keys(object).length === 0;
}
export function mapValues(object, fn) {
  const newObject = {};
  for (const key of Object.keys(object)) {
    newObject[key] = fn(object[key]);
  }
  return newObject;
}

import { computeBytesCount } from './utils/byteUtils';
export function createIdentityEncoder() {
  let output = '';
  let outputBytesCount = 0;
  return {
    isAsync: false,
    get isEmpty() {
      return !output;
    },
    write(data, callback) {
      const additionalEncodedBytesCount = computeBytesCount(data);
      outputBytesCount += additionalEncodedBytesCount;
      output += data;
      if (callback) {
        callback(additionalEncodedBytesCount);
      }
    },
    finish(callback) {
      callback(this.finishSync());
    },
    finishSync() {
      const result = {
        output,
        outputBytesCount,
        rawBytesCount: outputBytesCount,
        pendingData: ''
      };
      output = '';
      outputBytesCount = 0;
      return result;
    },
    estimateEncodedBytesCount(data) {
      return data.length;
    }
  };
}

import { getGlobalObject } from '../tools/getGlobalObject';
export function getEventBridge() {
  const eventBridgeGlobal = getEventBridgeGlobal();
  if (!eventBridgeGlobal) {
    return;
  }
  return {
    getCapabilities() {
      var _a;
      return JSON.parse(((_a = eventBridgeGlobal.getCapabilities) === null || _a === void 0 ? void 0 : _a.call(eventBridgeGlobal)) || '[]');
    },
    getPrivacyLevel() {
      var _a;
      return (_a = eventBridgeGlobal.getPrivacyLevel) === null || _a === void 0 ? void 0 : _a.call(eventBridgeGlobal);
    },
    getAllowedWebViewHosts() {
      return JSON.parse(eventBridgeGlobal.getAllowedWebViewHosts());
    },
    send(eventType, event, viewId) {
      const view = viewId ? {
        id: viewId
      } : undefined;
      eventBridgeGlobal.send(JSON.stringify({
        eventType,
        event,
        view
      }));
    }
  };
}
export function bridgeSupports(capability) {
  const bridge = getEventBridge();
  return !!bridge && bridge.getCapabilities().includes(capability);
}
export function canUseEventBridge(currentHost) {
  var _a;
  if (currentHost === void 0) {
    currentHost = (_a = getGlobalObject().location) === null || _a === void 0 ? void 0 : _a.hostname;
  }
  const bridge = getEventBridge();
  return !!bridge && bridge.getAllowedWebViewHosts().some(allowedHost => currentHost === allowedHost || currentHost.endsWith(`.${allowedHost}`));
}
function getEventBridgeGlobal() {
  return getGlobalObject().DatadogEventBridge;
}

import { addEventListener } from '../../browser/addEventListener';
import { combine } from '../../tools/mergeInto';
const CONTEXT_STORE_KEY_PREFIX = '_dd_c';
const storageListeners = [];
export function storeContextManager(configuration, contextManager, productKey, customerDataType) {
  const storageKey = buildStorageKey(productKey, customerDataType);
  storageListeners.push(addEventListener(configuration, window, "storage" /* DOM_EVENT.STORAGE */, ({
    key
  }) => {
    if (storageKey === key) {
      synchronizeWithStorage();
    }
  }));
  contextManager.changeObservable.subscribe(dumpToStorage);
  contextManager.setContext(combine(getFromStorage(), contextManager.getContext()));
  function synchronizeWithStorage() {
    contextManager.setContext(getFromStorage());
  }
  function dumpToStorage() {
    localStorage.setItem(storageKey, JSON.stringify(contextManager.getContext()));
  }
  function getFromStorage() {
    const rawContext = localStorage.getItem(storageKey);
    return rawContext !== null ? JSON.parse(rawContext) : {};
  }
}
export function buildStorageKey(productKey, customerDataType) {
  return `${CONTEXT_STORE_KEY_PREFIX}_${productKey}_${customerDataType}`;
}
export function removeStorageListeners() {
  storageListeners.map(listener => listener.stop());
}

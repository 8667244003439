import { elapsed } from '@datadog/browser-core';
import { waitPageActivityEnd } from '../../waitPageActivityEnd';
import { trackFirstHidden } from './trackFirstHidden';
export function trackLoadingTime(lifeCycle, domMutationObservable, windowOpenObservable, configuration, loadType, viewStart, callback) {
  let isWaitingForLoadEvent = loadType === "initial_load" /* ViewLoadingType.INITIAL_LOAD */;
  let isWaitingForActivityLoadingTime = true;
  const loadingTimeCandidates = [];
  const firstHidden = trackFirstHidden(configuration);
  function invokeCallbackIfAllCandidatesAreReceived() {
    if (!isWaitingForActivityLoadingTime && !isWaitingForLoadEvent && loadingTimeCandidates.length > 0) {
      const loadingTime = Math.max(...loadingTimeCandidates);
      if (loadingTime < firstHidden.timeStamp) {
        callback(loadingTime);
      }
    }
  }
  const {
    stop
  } = waitPageActivityEnd(lifeCycle, domMutationObservable, windowOpenObservable, configuration, event => {
    if (isWaitingForActivityLoadingTime) {
      isWaitingForActivityLoadingTime = false;
      if (event.hadActivity) {
        loadingTimeCandidates.push(elapsed(viewStart.timeStamp, event.end));
      }
      invokeCallbackIfAllCandidatesAreReceived();
    }
  });
  return {
    stop: () => {
      stop();
      firstHidden.stop();
    },
    setLoadEvent: loadEvent => {
      if (isWaitingForLoadEvent) {
        isWaitingForLoadEvent = false;
        loadingTimeCandidates.push(loadEvent);
        invokeCallbackIfAllCandidatesAreReceived();
      }
    }
  };
}

import { setInterval, clearInterval, Observable, addEventListener, ONE_SECOND, findCommaSeparatedValue } from '@datadog/browser-core';
export function createCookieObservable(configuration, cookieName) {
  const detectCookieChangeStrategy = window.cookieStore ? listenToCookieStoreChange(configuration) : watchCookieFallback;
  return new Observable(observable => detectCookieChangeStrategy(cookieName, event => observable.notify(event)));
}
function listenToCookieStoreChange(configuration) {
  return (cookieName, callback) => {
    const listener = addEventListener(configuration, window.cookieStore, "change" /* DOM_EVENT.CHANGE */, event => {
      // Based on our experimentation, we're assuming that entries for the same cookie cannot be in both the 'changed' and 'deleted' arrays.
      // However, due to ambiguity in the specification, we asked for clarification: https://github.com/WICG/cookie-store/issues/226
      const changeEvent = event.changed.find(event => event.name === cookieName) || event.deleted.find(event => event.name === cookieName);
      if (changeEvent) {
        callback(changeEvent.value);
      }
    });
    return listener.stop;
  };
}
export const WATCH_COOKIE_INTERVAL_DELAY = ONE_SECOND;
function watchCookieFallback(cookieName, callback) {
  const previousCookieValue = findCommaSeparatedValue(document.cookie, cookieName);
  const watchCookieIntervalId = setInterval(() => {
    const cookieValue = findCommaSeparatedValue(document.cookie, cookieName);
    if (cookieValue !== previousCookieValue) {
      callback(cookieValue);
    }
  }, WATCH_COOKIE_INTERVAL_DELAY);
  return () => {
    clearInterval(watchCookieIntervalId);
  };
}

import { getRelativeTime, isNumber } from '@datadog/browser-core';
import { RumPerformanceEntryType, supportPerformanceTimingEvent } from './performanceObservable';
export function getNavigationEntry() {
  if (supportPerformanceTimingEvent(RumPerformanceEntryType.NAVIGATION)) {
    const navigationEntry = performance.getEntriesByType(RumPerformanceEntryType.NAVIGATION)[0];
    if (navigationEntry) {
      return navigationEntry;
    }
  }
  const timings = computeTimingsFromDeprecatedPerformanceTiming();
  const entry = {
    entryType: RumPerformanceEntryType.NAVIGATION,
    initiatorType: 'navigation',
    name: window.location.href,
    startTime: 0,
    duration: timings.responseEnd,
    decodedBodySize: 0,
    encodedBodySize: 0,
    transferSize: 0,
    workerStart: 0,
    toJSON: () => ({
      ...entry,
      toJSON: undefined
    }),
    ...timings
  };
  return entry;
}
export function computeTimingsFromDeprecatedPerformanceTiming() {
  const result = {};
  const timing = performance.timing;
  for (const key in timing) {
    if (isNumber(timing[key])) {
      const numberKey = key;
      const timingElement = timing[numberKey];
      result[numberKey] = timingElement === 0 ? 0 : getRelativeTime(timingElement);
    }
  }
  return result;
}

import { display } from './display';
export function catchUserErrors(fn, errorMsg) {
  return (...args) => {
    try {
      return fn(...args);
    } catch (err) {
      display.error(errorMsg, err);
    }
  };
}

/**
 * Internal context keep returning v1 format
 * to not break compatibility with logs data format
 */
export function startInternalContext(applicationId, sessionManager, viewHistory, actionContexts, urlContexts) {
  return {
    get: startTime => {
      const viewContext = viewHistory.findView(startTime);
      const urlContext = urlContexts.findUrl(startTime);
      const session = sessionManager.findTrackedSession(startTime);
      if (session && viewContext && urlContext) {
        const actionId = actionContexts.findActionId(startTime);
        return {
          application_id: applicationId,
          session_id: session.id,
          user_action: actionId ? {
            id: actionId
          } : undefined,
          view: {
            id: viewContext.id,
            name: viewContext.name,
            referrer: urlContext.referrer,
            url: urlContext.url
          }
        };
      }
    }
  };
}

import { isChromium } from '../../../tools/utils/browserDetection';
import { getCurrentSite, areCookiesAuthorized, getCookie, setCookie } from '../../../browser/cookie';
import { tryOldCookiesMigration } from '../oldCookiesMigration';
import { SESSION_COOKIE_EXPIRATION_DELAY, SESSION_EXPIRATION_DELAY, SESSION_TIME_OUT_DELAY, SessionPersistence } from '../sessionConstants';
import { toSessionString, toSessionState, getExpiredSessionState } from '../sessionState';
import { SESSION_STORE_KEY } from './sessionStoreStrategy';
export function selectCookieStrategy(initConfiguration) {
  const cookieOptions = buildCookieOptions(initConfiguration);
  return areCookiesAuthorized(cookieOptions) ? {
    type: SessionPersistence.COOKIE,
    cookieOptions
  } : undefined;
}
export function initCookieStrategy(configuration, cookieOptions) {
  const cookieStore = {
    /**
     * Lock strategy allows mitigating issues due to concurrent access to cookie.
     * This issue concerns only chromium browsers and enabling this on firefox increases cookie write failures.
     */
    isLockEnabled: isChromium(),
    persistSession: persistSessionCookie(cookieOptions),
    retrieveSession: retrieveSessionCookie,
    expireSession: sessionState => expireSessionCookie(cookieOptions, sessionState, configuration)
  };
  tryOldCookiesMigration(cookieStore);
  return cookieStore;
}
function persistSessionCookie(options) {
  return session => {
    setCookie(SESSION_STORE_KEY, toSessionString(session), SESSION_EXPIRATION_DELAY, options);
  };
}
function expireSessionCookie(options, sessionState, configuration) {
  const expiredSessionState = getExpiredSessionState(sessionState, configuration);
  // we do not extend cookie expiration date
  setCookie(SESSION_STORE_KEY, toSessionString(expiredSessionState), configuration.trackAnonymousUser ? SESSION_COOKIE_EXPIRATION_DELAY : SESSION_TIME_OUT_DELAY, options);
}
function retrieveSessionCookie() {
  const sessionString = getCookie(SESSION_STORE_KEY);
  const sessionState = toSessionState(sessionString);
  return sessionState;
}
export function buildCookieOptions(initConfiguration) {
  const cookieOptions = {};
  cookieOptions.secure = !!initConfiguration.useSecureSessionCookie || !!initConfiguration.usePartitionedCrossSiteSessionCookie;
  cookieOptions.crossSite = !!initConfiguration.usePartitionedCrossSiteSessionCookie;
  cookieOptions.partitioned = !!initConfiguration.usePartitionedCrossSiteSessionCookie;
  if (initConfiguration.trackSessionAcrossSubdomains) {
    cookieOptions.domain = getCurrentSite();
  }
  return cookieOptions;
}

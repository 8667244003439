import { createBatch } from './batch';
import { createHttpRequest } from './httpRequest';
import { createFlushController } from './flushController';
export function startBatchWithReplica(configuration, primary, replica, reportError, pageExitObservable, sessionExpireObservable, batchFactoryImp = createBatch) {
  const primaryBatch = createBatchFromConfig(configuration, primary);
  const replicaBatch = replica && createBatchFromConfig(configuration, replica);
  function createBatchFromConfig(configuration, {
    endpoint,
    encoder
  }) {
    return batchFactoryImp({
      encoder,
      request: createHttpRequest(endpoint, configuration.batchBytesLimit, reportError),
      flushController: createFlushController({
        messagesLimit: configuration.batchMessagesLimit,
        bytesLimit: configuration.batchBytesLimit,
        durationLimit: configuration.flushTimeout,
        pageExitObservable,
        sessionExpireObservable
      }),
      messageBytesLimit: configuration.messageBytesLimit
    });
  }
  return {
    flushObservable: primaryBatch.flushController.flushObservable,
    add(message, replicated = true) {
      primaryBatch.add(message);
      if (replicaBatch && replicated) {
        replicaBatch.add(replica.transformMessage ? replica.transformMessage(message) : message);
      }
    },
    upsert: (message, key) => {
      primaryBatch.upsert(message, key);
      if (replicaBatch) {
        replicaBatch.upsert(replica.transformMessage ? replica.transformMessage(message) : message, key);
      }
    },
    stop: () => {
      primaryBatch.stop();
      if (replicaBatch) {
        replicaBatch.stop();
      }
    }
  };
}

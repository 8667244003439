import { toServerDuration, relativeToClocks, generateUUID } from '@datadog/browser-core';
import { createPerformanceObservable, RumPerformanceEntryType } from '../../browser/performanceObservable';
export function startLongTaskCollection(lifeCycle, configuration) {
  const performanceLongTaskSubscription = createPerformanceObservable(configuration, {
    type: RumPerformanceEntryType.LONG_TASK,
    buffered: true
  }).subscribe(entries => {
    for (const entry of entries) {
      if (entry.entryType !== RumPerformanceEntryType.LONG_TASK) {
        break;
      }
      if (!configuration.trackLongTasks) {
        break;
      }
      const startClocks = relativeToClocks(entry.startTime);
      const rawRumEvent = {
        date: startClocks.timeStamp,
        long_task: {
          id: generateUUID(),
          entry_type: "long-task" /* RumLongTaskEntryType.LONG_TASK */,
          duration: toServerDuration(entry.duration)
        },
        type: "long_task" /* RumEventType.LONG_TASK */,
        _dd: {
          discarded: false
        }
      };
      lifeCycle.notify(11 /* LifeCycleEventType.RAW_RUM_EVENT_COLLECTED */, {
        rawRumEvent,
        startTime: startClocks.relative,
        domainContext: {
          performanceEntry: entry
        }
      });
    }
  });
  return {
    stop() {
      performanceLongTaskSubscription.unsubscribe();
    }
  };
}

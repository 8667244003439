import { toStackTraceString } from '../../tools/stackTrace/handlingStack';
import { monitor } from '../../tools/monitor';
import { mergeObservables, Observable } from '../../tools/observable';
import { addEventListener } from '../../browser/addEventListener';
import { safeTruncate } from '../../tools/utils/stringUtils';
import { ErrorSource } from '../error/error.types';
import { clocksNow } from '../../tools/utils/timeUtils';
export const RawReportType = {
  intervention: 'intervention',
  deprecation: 'deprecation',
  cspViolation: 'csp_violation'
};
export function initReportObservable(configuration, apis) {
  const observables = [];
  if (apis.includes(RawReportType.cspViolation)) {
    observables.push(createCspViolationReportObservable(configuration));
  }
  const reportTypes = apis.filter(api => api !== RawReportType.cspViolation);
  if (reportTypes.length) {
    observables.push(createReportObservable(reportTypes));
  }
  return mergeObservables(...observables);
}
function createReportObservable(reportTypes) {
  return new Observable(observable => {
    if (!window.ReportingObserver) {
      return;
    }
    const handleReports = monitor((reports, _) => reports.forEach(report => observable.notify(buildRawReportErrorFromReport(report))));
    const observer = new window.ReportingObserver(handleReports, {
      types: reportTypes,
      buffered: true
    });
    observer.observe();
    return () => {
      observer.disconnect();
    };
  });
}
function createCspViolationReportObservable(configuration) {
  return new Observable(observable => {
    const {
      stop
    } = addEventListener(configuration, document, "securitypolicyviolation" /* DOM_EVENT.SECURITY_POLICY_VIOLATION */, event => {
      observable.notify(buildRawReportErrorFromCspViolation(event));
    });
    return stop;
  });
}
function buildRawReportErrorFromReport(report) {
  const {
    type,
    body
  } = report;
  return buildRawReportError({
    type: body.id,
    message: `${type}: ${body.message}`,
    originalError: report,
    stack: buildStack(body.id, body.message, body.sourceFile, body.lineNumber, body.columnNumber)
  });
}
function buildRawReportErrorFromCspViolation(event) {
  const message = `'${event.blockedURI}' blocked by '${event.effectiveDirective}' directive`;
  return buildRawReportError({
    type: event.effectiveDirective,
    message: `${RawReportType.cspViolation}: ${message}`,
    originalError: event,
    csp: {
      disposition: event.disposition
    },
    stack: buildStack(event.effectiveDirective, event.originalPolicy ? `${message} of the policy "${safeTruncate(event.originalPolicy, 100, '...')}"` : 'no policy', event.sourceFile, event.lineNumber, event.columnNumber)
  });
}
function buildRawReportError(partial) {
  return {
    startClocks: clocksNow(),
    source: ErrorSource.REPORT,
    handling: "unhandled" /* ErrorHandling.UNHANDLED */,
    ...partial
  };
}
function buildStack(name, message, sourceFile, lineNumber, columnNumber) {
  return sourceFile ? toStackTraceString({
    name,
    message,
    stack: [{
      func: '?',
      url: sourceFile,
      line: lineNumber !== null && lineNumber !== void 0 ? lineNumber : undefined,
      column: columnNumber !== null && columnNumber !== void 0 ? columnNumber : undefined
    }]
  }) : undefined;
}

import { display } from './display';
let onMonitorErrorCollected;
let debugMode = false;
export function startMonitorErrorCollection(newOnMonitorErrorCollected) {
  onMonitorErrorCollected = newOnMonitorErrorCollected;
}
export function setDebugMode(newDebugMode) {
  debugMode = newDebugMode;
}
export function resetMonitor() {
  onMonitorErrorCollected = undefined;
  debugMode = false;
}
export function monitored(_, __, descriptor) {
  const originalMethod = descriptor.value;
  descriptor.value = function (...args) {
    const decorated = onMonitorErrorCollected ? monitor(originalMethod) : originalMethod;
    return decorated.apply(this, args);
  };
}
export function monitor(fn) {
  return function () {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return callMonitored(fn, this, arguments);
  }; // consider output type has input type
}
export function callMonitored(fn, context, args) {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return fn.apply(context, args);
  } catch (e) {
    monitorError(e);
  }
}
export function monitorError(e) {
  displayIfDebugEnabled(e);
  if (onMonitorErrorCollected) {
    try {
      onMonitorErrorCollected(e);
    } catch (e) {
      displayIfDebugEnabled(e);
    }
  }
}
export function displayIfDebugEnabled(...args) {
  if (debugMode) {
    display.error('[MONITOR]', ...args);
  }
}
